.Filters{
  margin-bottom: 32px;
  margin-right: 80px;
  .gridContents {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  &--clearCollection {
    display: none;
    text-align: center;
    align-self: center;
    margin-top: 16px;
    svg{
      fill: $coral-base;
    }
  }
  &:hover{
    .Filters--clearCollection{
      display: block;
    }
  }
  &--fillCollection {
    display: flex;
    flex-grow: 3;
      > div{
      flex-grow: 1;
      flex-basis: 130px;
      margin-right: 16px;
      &:last-child{
        flex-grow: 2;
      }
    }
  }
  &--buttons{
    display: flex;
    > * {
      margin-right: 16px;
      &:last-child{
        margin-right: 0 ;
      }
    }
    &.hidden {
      flex-grow: 1;
      *{
        display: none;
      }
      
    }
  }
}
.ScreenTitle{
  display: flex;
  flex-direction: row;
  position: relative;
  
  > * {
    align-self: center;
    align-items: center;
  }
  .gridContents { 
    display: flex;
    svg{
      margin: 16px 16px 16px 0;
     }
  }
  .goBack{
    transition: all .2s ease-in-out;
    text-align: center;
    position: relative;
    svg{
      fill: $black-base;
    }
    &:hover{
      transform: translateX(-12px);
    }
  }

  .fakeButton{
    font-style: normal;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $white-base;
    height: 40px;
    width: auto;
    padding: 0px 24px;
    line-height:40px;
    border-radius: 20px;
    background-color: $coral-base;
    position: relative;
    margin-left: 32px;
    &:hover{
      background-color: $coral-darker-20;
    }
    &:active{
      background-color: $coral-darker-40;
      top: 2px;
    }
  }

  .grid2ColumnsContents.col2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

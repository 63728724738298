.PayableToIRD{
  .table{
    text-align: right;
    &--col.isHeader{
      width: 100px;
      text-align: left;
      
    }
    &--cell.isFirst{
      border-right: 1px solid $grey-base;
    }
    &--cell:nth-child(odd){
      margin-bottom: 8px;
      border-bottom: 2px solid $stone-lighter-40;
      &.isHeader{
        border-color: transparent;
      }
    }
  }
}
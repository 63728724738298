.TableHeader{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  &--order{
    padding-left: 16px
  }
  &.isAlignRight{
    justify-content: flex-end;
  }
}
.SideNav {
  
  height: 100vh;
  background: $black-base;
  color: $white-base;
  display: grid;
  position: fixed;
  width: 256px;
  z-index: 10000000;
  grid-template-rows:  80px 120px 60px 60px 60px 1fr 80px;
  grid-template-columns: 256px;
  grid-template-areas: 
  "logo"
  ". "
  "item1"
  "item2 " 
  "item3" 
  "." 
  "item4";
  
  &--link{
    display: flex;
    color: $white-base;
    @extend .subhead01;
    padding: 16px;
    text-decoration: none;
    border-left: $black-base 4px solid;
    &:first-of-type{
      grid-area: item1
    }
    &:nth-child(3){
      grid-area: item2
    }
    &:nth-child(4){
      grid-area: item3
    }
    svg{
      margin-right: 16px;
    }
    &.isSelected{
      background: rgba(255,255,255,.20);
      border-left: $gold-base 4px solid;
    }
    &:hover{
      color: $gold-base;
      svg{
        fill: $gold-base;
      }
    }
  }
  .logo{
    padding: 32px 16px;
    grid-area: logo;
    svg {
      height: 16px;
    }
  }
  .userinfo{
    grid-area: item4;
    align-items: center;
  }

  .min & {
    width: 64px;
    span{ 
      display: none;
    }
  }
}
.Card{
  display:flex;
  height: 100px;
  width:100px;
  border: 2px solid $purple-base;
  color: $purple-base;
  border-radius: 8px;
  padding: 10px;
  flex-direction: column ;
  margin-right: 8px;
  justify-content: space-between;
  transition: ease-in-out all .2s;
  cursor: pointer;

  &--header{
    display: flex;
    @extend .label;
    flex-direction: row;
    width: 100px;
  }
  &--content{
    @extend .header02;
  }
  &:hover{
    transform: scale(1.05);
  }
  &.active {
    background-color: $purple-base;
    color: white;
    cursor: default;
  }
  &.active:hover{
    transform: none;
  }
  &.slim {
    width:88px;
  }
}

$white-base : #FFFFFF;
$black-base : #27323F;
$black-darker-20 : #1F2832;
$black-darker-40 : #171E26;
$stone-base : #687079;
$stone-lighter-20 : #868D94;
$stone-lighter-40 : #A4A9AF;
$stone-darker-20 : #535A61;
$stone-darker-40 : #3E4349;
$grey-base : #E9EAEB;
$grey-lighter-20 : #EDEEEF;
$grey-lighter-40 : #F2F2F3;
$grey-darker-20 : #BABBBC;
$gold-base : #CDB072;
$gold-lighter-20 : #D7C08E;
$gold-lighter-40 : #E1D0AA;
$gold-darker-20 : #A48D5B;
$gold-darker-40 : #7B6A44;
$coral-base : #E37371;
$coral-lighter-20 : #E98F8D;
$coral-lighter-40 : #EEABAA;
$coral-darker-20 : #B65C5A;
$coral-darker-40 : #884544;
$blue-base : #5A88B3;
$blue-lighter-20 : #7BA0C2;
$blue-lighter-40 : #9CB8D1;
$blue-darker-20 : #486D8F;
$blue-darker-40 : #36526B;
$fiord-base : #3C4E63;
$fiord-lighter-20 : #637182;
$steel-blue-base : #4F6C88;
$steel-blue-lighter-20 : #7289A0;
$steel-blue-lighter-40 : #95A7B8;
$steel-blue-darker-20 : #3F566D;
$teal-base : #197E8F;
$purple-base : #46457D;
$orange-base : #EDA124;
$yellow-base: #F1C362;
$cyan-base: #AEE0DB;
$green-base: #28A47D;
$teal-lighter-20 : #4798A5;
$blue-highlight : #4889C5;
$coral-highlight : #F06764;
$gold-highlight : #DCB563;

.isAlert{
  color: $coral-highlight
}
.isOrange{
  color: $orange-base;
}
.isYellow{
  color: $yellow-base;
}
.isTeal{
  color: $teal-base;
}
.isGreen{
  color: $green-base;
}
.isCyan{
  color:$cyan-base;
}
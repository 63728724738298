.PopupDialog {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.6);
  &-box{
    position: relative;
    top: 30%;
    left:30%;
    width: 40%;
    height:30%;
    border-radius: 16px;
    line-height: 1.5;
    background-color: $white-base;
    box-shadow: 0px 8px 16px  rgba(0,0,0,0.5);
    
  }
  &--content {
    padding: 32px 32px 0px 32px
  }
  &--footer {
    display: flex;
    margin: 32px;
    flex-direction: row-reverse;
  }

}


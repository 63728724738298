.table {
  display: flex;
  flex-wrap: wrap;

  .isHeader {
    @extend .subhead04;
    border-color: transparent !important;

    .table--cell {
      border-color: transparent;
    }

    p {
      @extend .subhead04;
    }
  }

  .isSmall {
    .table--row {
      &:last-child {
        border: 2px solid $stone-lighter-40;
      }
    }
  }

  &--row {
    height: 44px;
    display: flex;
    flex-direction: row;
    border: 2px solid $stone-lighter-40;
    border-bottom: none;
    border-radius: 4px;
    box-sizing: border-box;

    &--showMore {
      display: flex;
      background: $grey-lighter-20;
      border: 2px solid $stone-lighter-40;
      border-radius: 0 0 4px 4px;
      border-top: none;
      padding-bottom: 8px;
      position: absolute;
      max-height: 400px;
      overflow-y: scroll;

      .table--row {
        min-height: 44px;
        height: auto !important;
        border: none;
        justify-content: flex-start;
      }

      .table--cell {
        border: transparent !important;
        min-height: 44px;
        height: auto !important;
        padding: 8px 0;
        justify-content: flex-start;
        flex-basis: unset;

        &.isHeader {
          justify-content: center;
        }
      }
    }
  }


  &--col {
    align-content: stretch;

    &.fixed-size {
      width: 140px;
    }

    .table--cell {
      height: 44px;
      border-top: 2px solid $stone-lighter-40;

      &:last-child {
        border-bottom: 2px solid $stone-lighter-40;

        &.isHeader {
          border-color: transparent;
        }
      }
    }
  }

  .isTruncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.isLarge {
    .table--cell {
      border-top: 2px solid $stone-lighter-40;
      box-shadow: 0 2px 0 $stone-lighter-40;

      &.isHeader {
        box-shadow: none;
      }

      &.isFirst {
        border-right: 2px solid $stone-lighter-40;
      }
    }
  }

  &--cell {
    border-right: 1px solid $grey-base;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

    a {
      color: $blue-highlight;
      font-weight: 700;
    }

    .isAlignRight {
      text-align: right;
    }

    .isAlignCenter{
      text-align: center;
    }

    &.isOdd {
      background-color: #edeeef;
    }

    &.isEven {
      background-color: white;
    }

    &.isFirst {
      border-left: 2px solid $stone-lighter-40;
      border-radius: 4px 0 0 4px
    }

    &.isLast {
      border-right: 2px solid $stone-lighter-40;
      border-radius: 0 4px 4px 0
    }

    &.isHeader {
      border-color: transparent !important;
    }

    p {
      @extend .paragraph-small;
      padding: 0 16px;

      b {
        font-weight: 800;
      }
    }

    small {
      @extend .label;
      color: $stone-base;
      font-style: italic;
      padding: 0 16px;
      padding-bottom: 4px;
    }

    &.first {
      border-right: 2px solid $stone-lighter-40;
    }
  }

}


.ReactVirtualized__Table__headerRow {
  border: none;
  span {
    padding: 0 16px
  }
}

.ReactVirtualized__Table__row {

  &:last-of-type{
    border-bottom: 2px solid $stone-lighter-40;
  }
}
// .ReactVirtualized__Grid__innerScrollContainer {
//   min-height: 800px;
// }
.StaticExceptionTable .ReactVirtualized__Grid__innerScrollContainer {
  padding-bottom: 440px;
}

// Light border around Large Table that do not overflow
.table>div>div:last-child>.ReactVirtualized__Grid:nth-child(2) {
  border: 1px solid $grey-lighter-20;
  border-left: none;
  border-right: none;
}

// Light border around Large Table that overflow with fixed header and columnHeader
.table.isLarge>div>div:last-child>.ReactVirtualized__Grid:nth-child(1) {
  border-bottom: 1px solid $grey-lighter-20;
  border-top: 1px solid $grey-lighter-20;
}

.table.isLarge>div>div:last-child>.ReactVirtualized__Grid:nth-child(2) {
  border: 1px solid $grey-lighter-20;
  border-left: none;
}
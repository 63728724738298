.title01{
  font-size: 36px;
  line-height: 40px;
}

.title02{
  font-size: 30px;
  line-height: 34px;
}


.header01{
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
}

.header02{
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
}

.header03{
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
}

.subhead01{
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}

.subhead02{
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}

.subhead03{
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.subhead04{
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.paragraph{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  &-link{
    font-weight: bold;
  }
}


.paragraph-small{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  &-link{
    font-weight: bold;
  }
}

.label{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  &-highlight{
    font-weight: 600;
  }
}

strong{
  font-weight: 700;
}
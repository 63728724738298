body {
  margin: 0;
  min-width: 1080px;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black-base;
}

.app {
  display: grid;
  grid-template-columns: 256px 1fr;
  grid-template-rows: 40px 1fr;
  min-height: 100vh;
  grid-template-areas:
    "sideNav header "
    "sideNav content";
  &.min {
    grid-template-columns: 64px 1fr;
  }

  .sideNavPlaceholder {
    grid-area: sideNav;
  }

  main {
    grid-area: content;
    padding-bottom: 80px;
    section{
      padding: 0 ;
    }
    .grid{
      display: grid;
      grid-template-columns: 80px 1fr;
      grid-template-areas: "Actions Contents";
      &Actions{
        grid-area: Actions
      }
      &Contents{
        grid-area: Contents
      }
    }
    .grid2Columns{
      display: grid;
      grid-template-columns: 80px 1fr 2fr;
      margin: 40px 0 32px;
      align-items: center;
      grid-template-areas: "Actions Col1Contents Col2Contents";
      &Actions{
        grid-area: Actions
      }
      &Contents.col1{
        grid-area: Col1Contents
      }
      &Contents.col2{
        grid-area: Col2Contents;
        margin-right: 80px;
      }
    }

    .containFilters {
      display: flex;
      flex-direction: row;
      margin-bottom: 32px;
      margin-right: 80px;
      position: relative;
    }
  }
  .layoutRightAside{
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-areas: "Section Aside ";
    section{
      grid-area: Section;
    }
    aside{
      grid-area: Aside;
      background-color: $grey-base;
      padding: 0 80px;
      min-height: 100vh;
    
    }
  }
}
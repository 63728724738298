.Upload{
  display:flex;
  height: 100px;
  width:100px;
  border: 2px dashed $grey-darker-20;
  border-radius: 16px;
  padding: 10px;
  flex-direction: column ;
  margin-right: 8px;
  justify-content: center;
  transition: ease-in-out all .2s;
  position: absolute;
  right: 80px;
  &--icon{
    align-self: center;
    transition: ease-in-out all .2s;
    padding: 8px;
    svg{
      fill: $grey-darker-20;
      transition: ease-in-out all .2s;
    }
  }
  &--text{
    align-self: center;
    color:$grey-darker-20;
    @extend .paragraph-link;
    transition: ease-in-out all .2s;
  }
  &:hover{
    border: 2px dashed $blue-highlight;
    transform: scale(1.05);
    .Upload--icon{
      transform: translateY(-8px);
      svg{
        fill: $blue-highlight
      }
    }
    .Upload--text{
      color:$blue-highlight;
    }
  }
}